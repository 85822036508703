import { useState, useEffect, useMemo, useCallback } from 'react';
import styles from '../../../../scss/_variables.scss';
import LicensePlateInput from '../../LicensePlateInput/LicensePlateInput';
import Button from '../../bhComponents/Button';
import {
  Toevoegen1Container,
  Toevoegen1InputWrapper,
  Toevoegen1Title,
  Toevoegen1ContentSpan,
  Toevoegen1MainWrapper,
} from './Toevoegen1.styles';
import classes from './index.module.scss';
import useBrands from 'app/hooks/useBrands';
import useModels from 'app/hooks/useModels';

const Toevoegen1 = ({
  kentekenInputValue,
  setKentekenInputValue,
  setAutogegevens,
  carModelAndYear,
  merkValue,
  setMerkValue,
  modelValue,
  setModelValue,
  bouwjaarValue,
  setBouwjaarValue,
  toastId,
  setToastId,
  verifyCarInformation,
  toastList,
  setToastList,
}) => {
  const [buttonActive, setButtonActive] = useState(false);

  const [localBrandValue, setLocalBrandValue] = useState({ label: '', value: '' });
  const [localModelValue, setLocalModelValue] = useState({ label: '', value: '' });
  const [localYearValue, setLocalYearValue] = useState({ label: '', value: '' });

  const handleClick = useCallback(() => {
    verifyCarInformation();
  }, []);

  const { data: brandsData } = useBrands();
  const { data: modelsData } = useModels(merkValue);

  const brandOptions = useMemo(() => {
    if (brandsData?.brands) {
      return brandsData?.brands.map((brand) => ({ label: brand, value: brand }));
    } else {
      return [];
    }
  }, [brandsData?.brands]);

  const modelOptions = useMemo(() => {
    if (modelsData?.models && localBrandValue?.label !== '' && localBrandValue !== undefined) {
      return modelsData?.models.map((modelInfo) => ({ label: modelInfo.model, value: modelInfo.model }));
    } else {
      return [];
    }
  }, [modelsData?.models]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear() + 1;
    const tempModelData =
      modelsData?.models && modelsData.models.filter((item) => item?.model === localModelValue?.value)[0];
    const tempYearOptions = [];

    if (tempModelData?.bouwjaar_van) {
      for (let i = tempModelData.bouwjaar_van; i <= (tempModelData.bouwjaar_tot || currentYear); i++) {
        tempYearOptions.push({
          label: i.toString(),
          value: i.toString(),
        });
      }
      return tempYearOptions;
    }
    return [];
  }, [localModelValue]);

  const handleBrandChange = useCallback((_, option) => {
    setLocalBrandValue(option);
    setMerkValue(option?.value);
  }, []);

  const handleModelChange = useCallback((_, option) => {
    setLocalModelValue(option);
    setModelValue(option?.value);
  }, []);

  const handleYearChange = useCallback((_, option) => {
    setLocalYearValue(option);
    setBouwjaarValue(option?.value);
  }, []);

  useEffect(() => {
    if (merkValue === '' && modelValue === '' && bouwjaarValue === '') {
      setLocalBrandValue();
      setLocalModelValue();
      setLocalYearValue();
    }
  }, [merkValue, modelValue, bouwjaarValue]);

  useEffect(() => {
    if (localBrandValue?.label) {
      setModelValue('');
      setLocalModelValue();
      setBouwjaarValue('');
      setLocalYearValue();
    }
  }, [localBrandValue]);

  useEffect(() => {
    if (localModelValue?.label && localYearValue?.label) {
      const modelAvailableInThatYear = yearOptions?.filter((yearObj) => yearObj?.label === localYearValue?.label);
      if (!modelAvailableInThatYear?.length) {
        setBouwjaarValue('');
        setLocalYearValue();
      }
    }
  }, [localModelValue]);

  return (
    <Toevoegen1Container backgroundColor={styles.white}>
      <Toevoegen1MainWrapper>
        <Toevoegen1Title>Herkomst</Toevoegen1Title>
        <Toevoegen1ContentSpan>
          Voer het kenteken in van het voertuig van herkomst of voer het merk, model en bouwjaar van het voertuig in.
        </Toevoegen1ContentSpan>
        <Toevoegen1InputWrapper>
          <LicensePlateInput
            onDisable={setButtonActive}
            carModelAndYear={carModelAndYear}
            kentekenInputValue={kentekenInputValue}
            handleSearchButtonClick={handleClick}
            handleBrandChange={handleBrandChange}
            handleModelChange={handleModelChange}
            localModelValue={localModelValue}
            setToastList={setToastList}
            localYearValue={localYearValue}
            setToastId={setToastId}
            toastList={toastList}
            brandOptions={brandOptions}
            handleYearChange={handleYearChange}
            localBrandValue={localBrandValue}
            modelOptions={modelOptions}
            yearOptions={yearOptions}
            toastId={toastId}
            setAutogegevens={setAutogegevens}
            setKentekenInputValue={setKentekenInputValue}
          />

          <Button className={classes.button} disabled={buttonActive} onClick={handleClick}>
            Verdergaan
          </Button>
        </Toevoegen1InputWrapper>
      </Toevoegen1MainWrapper>
    </Toevoegen1Container>
  );
};

export default Toevoegen1;
