import { useMutation } from '@tanstack/react-query';

import { getVoorraadPageResults } from 'app/api/voorraad';

type VoorraadPageSearchForm = {
  id: number;
  page: number;
  merk: string;
  model: string;
  bouwjaar: string;
  sortField: string;
  ascending: boolean | string;
  condition: Array<string>;
  inches: Array<string>;
  brand: Array<string>;
  season: Array<string>;
  priceRangeStart: number;
  priceRangeEnd: number;
};

const useVoorraad = () => {
  const voorraadPageSearchMutation = useMutation(getVoorraadPageResults);

  const onSearchClick = (data: VoorraadPageSearchForm) => voorraadPageSearchMutation.mutate(data);

  const onClearErrorMessage = () => voorraadPageSearchMutation.reset();

  const { isLoading, isError, error, data } = voorraadPageSearchMutation;

  return {
    voorraadPageSearchMutation,
    onSearchClick,
    onClearErrorMessage,
    isLoading,
    isError,
    error,
    data,
  };
};

export default useVoorraad;
