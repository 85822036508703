import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Conditions } from '../../form/checkbox-list';
import { HomePageContainer, MobileHomePageTransparentBackground } from './Homepage.styles';
import usePriceRange from 'app/hooks/usePriceRange';
import useHomePage from 'app/hooks/useHomePage';
import { QueryKeys } from 'app/helpers/QueryKeys';
import Zoeken from 'app/components/HomeComponents/Zoeken/Zoeken';
import ToastContainer from 'app/components/Toast/ToastContainer';
import Results from 'app/components/HomeComponents/Results/Results';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import { handleSubmit } from 'app/helpers/utils';

const Homepage = () => {
  const queryClient = useQueryClient();

  const [isSearchEnabled, setSearchEnabled] = useState(false);

  const [kentekenInputValue, setKentekenInputValue] = useState('');

  const [autoGegevens, setAutogegevens] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  const [carModelAndYear, setCarModelAndYear] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  const [merkValue, setMerkValue] = useState('');

  const [modelValue, setModelValue] = useState('');

  const [bouwjaarValue, setBouwjaarValue] = useState('');

  const [sorterenValue, setSorterenValue] = useState({
    label: 'Toegevoegd: nieuw naar oud',
    value: 'Toegevoegd: nieuw naar oud',
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [seasonValue, setSeasonValue] = useState([]);

  const [searchMenuState, setSearchMenuState] = useState(false);

  const [filterSearchValues, setFilterSearchValues] = useState([]);

  const [priceRangeValues, setPriceRangeValues] = useState([]);

  const min = useRef(priceRangeValues[0]);
  const max = useRef(priceRangeValues[1]);

  const [filterConditieArrayUi, setFilterConditieArrayUi] = useState([]);
  const [filterMerkArrayUi, setFilterMerkArrayUi] = useState([]);

  const [searchedWithCarDetails, setSearchedWithCarDetails] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  const [searchedWithFilters, setSearchedWithFilters] = useState({});

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const { width } = useWindowSize();
  const isMobileScreen = useMemo(() => width && width < breakpoints.lg, [width]);

  const merkValueOverride = useMemo(
    () => (autoGegevens.merk && autoGegevens.merk !== 'N/A' && autoGegevens.merk) || merkValue || '',
    [autoGegevens.merk, merkValue]
  );

  const modelValueOverride = useMemo(
    () => (autoGegevens.type && autoGegevens.type !== 'N/A' && autoGegevens.type) || modelValue || '',
    [autoGegevens.type, modelValue]
  );

  const bouwjaarValueOverride = useMemo(
    () => (autoGegevens.bouwjaar && autoGegevens.bouwjaar !== 'N/A' && autoGegevens.bouwjaar) || bouwjaarValue || '',
    [autoGegevens.bouwjaar, bouwjaarValue]
  );

  const sortedFilterSearchValues = useMemo(
    () => [...filterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride].sort(),
    [filterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride]
  );

  const searchPerformedWithCarData = useCallback(() => {
    setSearchedWithCarDetails({
      merk: merkValueOverride,
      type: modelValueOverride,
      bouwjaar: bouwjaarValueOverride,
    });
  }, [merkValueOverride, modelValueOverride, bouwjaarValueOverride]);

  const searchPerformedWithFilters = useCallback(() => {
    const allFiltersApplied = [...filterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride];
    setSearchedWithFilters(allFiltersApplied.sort());
  }, [filterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride]);

  useEffect(() => {
    if (isMobileScreen && searchMenuState) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  useEffect(() => {
    if (isSearchEnabled && priceRangeValues.length !== 0) {
      searchPerformedWithCarData();
      searchPerformedWithFilters();
      onSearchClick(homePageSearchDataObj());
    }
  }, [isSearchEnabled]);

  useEffect(() => {
    if (isSearchEnabled && priceRangeValues.length !== 0) {
      if (JSON.stringify(searchedWithFilters) === JSON.stringify(sortedFilterSearchValues)) {
        searchPerformedWithCarData();
        searchPerformedWithFilters();
        onSearchClick(homePageSearchDataObj());
      } else {
        setCurrentPage(1);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      isSearchEnabled &&
      priceRangeValues.length !== 0 &&
      currentPage === 1 &&
      JSON.stringify(searchedWithFilters) !== JSON.stringify(sortedFilterSearchValues)
    ) {
      searchPerformedWithCarData();
      searchPerformedWithFilters();
      onSearchClick(homePageSearchDataObj());
    }
  }, [currentPage]);

  useEffect(() => {
    if (autoGegevens.merk !== '') {
      setCarModelAndYear({
        make: autoGegevens.merk,
        model: autoGegevens.type,
        year: autoGegevens.bouwjaar,
      });
    }
  }, [autoGegevens]);

  const cachedResult = queryClient.getQueryData([QueryKeys.Kenteken, kentekenInputValue]);

  const cachedPriceRangeValues = queryClient.getQueryData([QueryKeys.PriceRange]);

  useEffect(() => {
    if (priceRangeValues.length === 0 && cachedPriceRangeValues) {
      min.current = 0;
      max.current = cachedPriceRangeValues?.max;
      setPriceRangeValues([0, cachedPriceRangeValues?.max]);
      setSearchEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (kentekenInputValue.length === 6 && cachedResult) {
      setAutogegevens({
        merk: cachedResult.merk,
        type: cachedResult.type,
        bouwjaar: cachedResult.bouwjaar,
      });
    }
  }, [kentekenInputValue]);

  useEffect(() => {
    if (
      (merkValue !== '' || modelValue !== '' || bouwjaarValue !== '') &&
      (kentekenInputValue !== '' || autoGegevens.merk !== '')
    ) {
      setAutogegevens({
        merk: '',
        type: '',
        bouwjaar: '',
      });
      setKentekenInputValue('');
    }
  }, [merkValue, modelValue, bouwjaarValue]);

  useEffect(() => {
    if ((merkValue !== '' || modelValue !== '' || bouwjaarValue !== '') && kentekenInputValue !== '') {
      setMerkValue('');
      setModelValue('');
      setBouwjaarValue('');
    }
  }, [kentekenInputValue]);

  const { isError: isPriceRangeError, error: priceRangeError } = usePriceRange(
    setPriceRangeValues,
    setSearchEnabled,
    min,
    max
  );

  useEffect(() => {
    if (isPriceRangeError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: priceRangeError?.message,
        additionalMessage: priceRangeError?.response?.statusText,
      };

      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isPriceRangeError]);

  const {
    data: ProductSearchResultsData,
    onSearchClick,
    onClearErrorMessage: onHomepageClearErrorMessage,
    isLoading: isHomepageLoading,
    isError: isHomepageError,
    error: homepageError,
  } = useHomePage();

  useEffect(() => {
    if (isHomepageError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: homepageError?.message,
        additionalMessage: homepageError?.response?.statusText,
        onClose: onHomepageClearErrorMessage,
      };

      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isHomepageError]);

  const totalPages = useMemo(
    () =>
      ProductSearchResultsData
        ? Math.ceil(ProductSearchResultsData?.total_results / ProductSearchResultsData?.results_per_page)
        : 1,
    [ProductSearchResultsData?.total_results, ProductSearchResultsData?.results_per_page]
  );

  const homePageSearchDataObj = () => {
    const TempConditionFilters = filterSearchValues.filter(
      (item) => item === Conditions.USED || item === Conditions.GOOD || item === Conditions.NEW
    );
    const TempBrandFilters = filterSearchValues.filter(
      (item) =>
        item !== Conditions.USED &&
        item !== Conditions.GOOD &&
        item !== Conditions.NEW &&
        item !== 'Zomer' &&
        item !== 'All Season' &&
        item !== 'Winter' &&
        isNaN(item) &&
        !item.includes('€')
    );
    const TempInchFilters = filterSearchValues.filter((item) => !isNaN(item) && !item.includes('€'));

    const TempSeasonFilters = seasonValue.map((item) => (item === 'Zomer' ? 'Summer' : item));

    const sortField = sorterenValue.value.includes('Toegevoegd')
      ? 'date_added'
      : sorterenValue.value.includes('Prijs')
      ? 'price'
      : '';

    const ascending = () => {
      switch (sorterenValue.value) {
        case 'Toegevoegd: nieuw naar oud':
          return false;
        case 'Toegevoegd: oud naar nieuw':
          return true;
        case 'Prijs: hoog naar laag':
          return false;
        case 'Prijs: laag naar hoog':
          return true;
        default:
          return '';
      }
    };

    const data = {
      page: currentPage,
      merk: merkValueOverride,
      model: modelValueOverride,
      bouwjaar: bouwjaarValueOverride,
      sortField,
      ascending: ascending(),
      condition: TempConditionFilters,
      brand: TempBrandFilters,
      season: TempSeasonFilters,
      inches: TempInchFilters,
      priceRangeStart: priceRangeValues[0],
      priceRangeEnd: priceRangeValues[1],
    };

    return data;
  };

  const isCarInfoRequired = useMemo(
    () => merkValue !== '' || modelValue !== '' || bouwjaarValue !== '',
    [merkValue, modelValue, bouwjaarValue]
  );

  const isCarInfoInvalid = useMemo(
    () => !(merkValue !== '' && modelValue !== '' && bouwjaarValue !== ''),
    [merkValue, modelValue, bouwjaarValue]
  );

  const isPriceRangeInvalid = useMemo(
    () =>
      priceRangeValues[0] === null ||
      priceRangeValues[0] < min.current ||
      priceRangeValues[0] >= priceRangeValues[1] ||
      priceRangeValues[1] === undefined ||
      priceRangeValues[1] <= priceRangeValues[0],
    [priceRangeValues, min.current]
  );

  const handleSearchButtonClick = useCallback(() => {
    if (isPriceRangeInvalid || (isCarInfoRequired && isCarInfoInvalid)) {
      return;
    }
    if (currentPage === 1) {
      searchPerformedWithCarData();
      searchPerformedWithFilters();
      onSearchClick(homePageSearchDataObj());
    } else if (currentPage && currentPage !== 1) {
      setCurrentPage(1);
    }
    if (isMobileScreen) {
      setSearchMenuState(false);
    }
  }, [
    isPriceRangeInvalid,
    isCarInfoRequired,
    isCarInfoInvalid,
    currentPage,
    searchPerformedWithCarData,
    searchPerformedWithFilters,
    onSearchClick,
    homePageSearchDataObj,
    isMobileScreen,
  ]);

  const refreshUponProductResultCardAction = useCallback(() => {
    onSearchClick(homePageSearchDataObj());
  }, [onSearchClick, homePageSearchDataObj]);

  return (
    <form onSubmit={handleSubmit}>
      <HomePageContainer>
        <Zoeken
          isSearchMenuOpen={searchMenuState}
          isMobileScreen={isMobileScreen}
          kentekenInputValue={kentekenInputValue}
          setKentekenInputValue={setKentekenInputValue}
          autoGegevens={autoGegevens}
          setAutogegevens={setAutogegevens}
          carModelAndYear={carModelAndYear}
          filterSearchValues={filterSearchValues}
          setFilterSearchValues={setFilterSearchValues}
          setSearchMenuState={setSearchMenuState}
          merkValue={merkValue}
          setMerkValue={setMerkValue}
          modelValue={modelValue}
          setModelValue={setModelValue}
          bouwjaarValue={bouwjaarValue}
          setBouwjaarValue={setBouwjaarValue}
          sorterenValue={sorterenValue}
          setSorterenValue={setSorterenValue}
          seasonValue={seasonValue}
          setSeasonValue={setSeasonValue}
          priceRangeValues={priceRangeValues}
          setPriceRangeValues={setPriceRangeValues}
          handleSearchButtonClick={handleSearchButtonClick}
          filterMerks={ProductSearchResultsData?.brands}
          filterInches={ProductSearchResultsData?.inches}
          filterConditie={ProductSearchResultsData?.conditions}
          filterConditieArrayUi={filterConditieArrayUi}
          setFilterConditieArrayUi={setFilterConditieArrayUi}
          filterMerkArrayUi={filterMerkArrayUi}
          setFilterMerkArrayUi={setFilterMerkArrayUi}
          toastId={toastId}
          setToastId={setToastId}
          toastList={toastList}
          setToastList={setToastList}
          min={min}
          max={max}
          isCarInfoRequired={isCarInfoRequired}
        />
        <Results
          isSearchMenuOpen={searchMenuState}
          setSearchMenuState={setSearchMenuState}
          data={ProductSearchResultsData?.data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageStart={1}
          totalPages={totalPages}
          isLoading={isHomepageLoading}
          searchedWithCarDetails={searchedWithCarDetails}
          toastId={toastId}
          setToastId={setToastId}
          toastList={toastList}
          setToastList={setToastList}
          refreshUponProductResultCardAction={refreshUponProductResultCardAction}
          isValid={!isPriceRangeInvalid && (isCarInfoRequired ? !isCarInfoInvalid : true)}
        />

        {isMobileScreen && searchMenuState && <MobileHomePageTransparentBackground />}
        <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      </HomePageContainer>
    </form>
  );
};

export default Homepage;
