import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ZoekenMainWrapper = styled.div`
  width: ${(props) => props.width};
  min-width: 375px;
  max-width: 605px;
  background-color: ${(props) => props.background};
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 0 8px;
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  position: ${(props) => props.position};
  z-index: 2;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: ${(props) => props.display};
    box-sizing: border-box;
    min-width: 350px;
    margin-bottom: 0;
    top: 83px;
    bottom: 20px;
    overflow: auto;
  }

  @media screen and (min-width: 601px) and (max-width: ${breakpoints.lg - 1}px) {
    top: 91px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    min-width: 320px;
    padding: 1rem;
    width: auto;
    left: 11px;
    right: 11px;
  }
`;

export const ZoekenTitleWrapper = styled.div`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 1rem;
`;

export const MobileZoekenTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 1rem;
  }
`;

export const MobileZoekenTitleContent = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

export const MobileZoekenTitleIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ZoekenKentekenInputWrapper = styled.div`
  margin-top: 23px;

  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    padding: 0 40px;
  }
`;

export const ZoekenOfLineWrapper = styled.h2`
  text-align: center;
  border-bottom: 1px solid #707070;
  line-height: 0.1em;

  font-size: 16px;
  color: #666666;
  margin-top: 30px;
  margin-bottom: 32px;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const ZoekenOfLineText = styled.span`
  background: white;
  padding: 0 10px;
`;

export const ZoekenMerkInputWrapper = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const ZoekenModelAndBouwjaarWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  gap: 8px;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const ZoekenModelInputWrapper = styled.div`
  width: 100%;
`;

export const ZoekenBouwjaarWrapper = styled.div``;

export const ZoekenCarModelAndYearWrapper = styled.div`
  margin-top: 22px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  padding: 10px 11px;
  background-color: ${(props) => props.background};

  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    margin: 0 40px;
  }
`;

export const ZoekenCarModelAndYearIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
`;
export const ZoekenCarModelAndYearTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`;

export const ZoekenSorterenwrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ZoekenSorterenTextWrapper = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const ExpandablesWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const ZoekenFilterChipWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 6.5px;
`;

export const ZoekenSeizonContentWrapper = styled.div`
   {
    padding-right: 0.5px;
    padding-top: 5px;
    height: fit-content;
    margin-bottom: -11px;
  }
`;

export const ZoekenMerkAccordionWrapper = styled.div``;

export const ZoekenMerkContentWrapper = styled.div``;

export const ZoekenPrijsAccordionWrapper = styled.div``;

export const ZoekenInchWrapper = styled.div``;

export const ZoekenInchAccordionWrapper = styled.div`
  margin-bottom: 1.5rem;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const ZoekenPrijsContentWrapper = styled.div`
  padding: 1.875rem 0.5rem 0 0.5rem;
`;

export const ZoekenSearchButtonWrapper = styled.div`
  margin-top: auto;

  @media screen and (max-width: ${breakpoints.md}px) {
    left: 20px;
    right: 20px;
  }

  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    left: 40px;
    right: 40px;
  }

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    position: ${(props) => props.position};
    bottom: 20px;
  }
`;
