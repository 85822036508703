import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useNavigate, createSearchParams } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  ProductResultCardWrapper,
  ProductResultCardContainer,
  ProductResultCardImageWrapper,
  ProductResultCardIconWrapper,
  ProductResultCardInfoWrapper,
  ProductResultCardInfoLeftWrapper,
  ProductResultCardInfoLeftPriceWrapper,
  ProductResultCardStatusTextWrapper,
  ProductResultCardInfoLeftSeasonWrapper,
  ProductResultCardInfoLeftSeasonTextWrapper,
  ProductResultCardInfoRightWrapper,
  ProductResultCardInfoRightIconWrapper,
  ProductResultSeasonIconImgWrapper,
  ProductResultStockMenuBackground,
  ProductResultPopupBackground,
  ProductResultStockMenuWrapper,
  ProductResultStockMenuItemWrapper,
  ProductResultCardWhiteSpace,
} from './ProductResultCard.styles';
import { breakpoints } from 'app/config/breakpoints';
import useWindowSize from 'app/hooks/useWindowSize';
import WinterIcon from 'assets/svg/ac_unit_black_24dp.svg';
import SummerWinterIcon from 'assets/svg/winter_summer_black_24dp.svg';
import SummerIcon from 'assets/svg/light_mode_black_24dp.svg';
import useAddFavoriet from 'app/hooks/Favorite/useAddFavoriet';
import useDeleteFavoriet from 'app/hooks/Favorite/useDeleteFavoriet';
import Popup from 'app/components/Popup';

import PriceLabel from 'app/components/price-label';
import { ToastListType } from 'app/helpers/types';

type ProductResultCardPropsType = {
  status?: string;
  price: number;
  season: string;
  image: string;
  isFavorite?: boolean;
  id: string;
  isOwner: boolean;
  condition: string;
  searchedWithCarDetails?: {
    merk: string;
    type: string;
    bouwjaar: string;
  };
  toastId: number;
  setToastId: React.Dispatch<React.SetStateAction<number>>;
  toastList: Array<ToastListType>;
  setToastList: React.Dispatch<React.SetStateAction<Array<ToastListType>>>;
  imageId?: number;
  advertentieId?: number;
  isListerPage?: boolean;
  refreshUponProductResultCardAction?: () => void;
  imageUrl?: string;
};

enum ConditionsType {
  NEW = 'NEW',
  USED = 'USED',
  GOOD = 'GOOD',
}

const ProductResultCard: React.FC<ProductResultCardPropsType> = ({
  status = '',
  price,
  season,
  image,
  isFavorite = false,
  id,
  isOwner,
  condition,
  searchedWithCarDetails,
  toastId,
  setToastId,
  toastList,
  setToastList,
  advertentieId,
  isListerPage,
  refreshUponProductResultCardAction,
  imageUrl,
}) => {
  const [favoriteState, setFavoriteState] = useState(isFavorite);

  const [stockMenuState, setStockMenuState] = useState(false);

  const [imgHeight, setHeight] = useState<number>();

  const [imageFile, setImageFile] = useState<string>();

  const [, setOpties] = useState(false);
  const [selectedOpties, setSelectedOpties] = useState('');

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current?.offsetWidth);
    }
  }, [width]);

  useEffect(() => {
    if (!imageFile) {
      if (imageUrl) {
        // onClickGetImage({ url: imageUrl });
        setImageFile(imageUrl);
      } else {
        setImageFile(image);
      }
    }
  }, [imageUrl]);

  useEffect(() => {
    // if (stockMenuState) {
    // 	document.body.style.overflow = 'hidden';
    // }
    // return () => {
    // 	document.body.style.overflow = 'unset';
    // };
  });

  let paramsProductForSaleNotOwned = {
    merk: '',
    type: '',
    bouwjaar: '',
  };

  if (searchedWithCarDetails) {
    paramsProductForSaleNotOwned = {
      merk: searchedWithCarDetails.merk,
      type: searchedWithCarDetails.type,
      bouwjaar: searchedWithCarDetails.bouwjaar,
    };
  }

  let seasonIcon;
  switch (season) {
    case 'SUMMER': {
      seasonIcon = <ProductResultSeasonIconImgWrapper src={SummerIcon} />;
      break;
    }
    case 'WINTER': {
      seasonIcon = <ProductResultSeasonIconImgWrapper src={WinterIcon} />;
      break;
    }
    default: {
      seasonIcon = <ProductResultSeasonIconImgWrapper src={SummerWinterIcon} />;
      break;
    }
  }

  const {
    onClickAddFavorieten,
    onClearErrorMessage: onAddClearErrorMessage,
    isLoading: isAddLoading,
    isError: isAddError,
    isSuccess: isAddSuccess,
    error: addError,
  } = useAddFavoriet(setFavoriteState);

  useEffect(() => {
    if (isAddError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: addError?.message,
        additionalMessage: addError?.response?.data.detail,
        onClose: onAddClearErrorMessage,
      };

      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isAddError]);

  useEffect(() => {
    if (isAddSuccess) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'Het product is succesvol toegevoegd aan uw favorieten',
      };
      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isAddSuccess]);

  const {
    onClickDeleteFavorieten,
    onClearErrorMessage: onDeleteClearErrorMessage,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    error: deleteError,
  } = useDeleteFavoriet(setFavoriteState);

  useEffect(() => {
    if (isDeleteError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: deleteError?.message,
        additionalMessage: deleteError?.response?.data.detail,
        onClose: onDeleteClearErrorMessage,
      };

      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'Het product is succesvol verwijderd uit uw favorieten',
      };
      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isDeleteSuccess]);

  const payloadData = () => ({
    id,
  });

  const handleFavoriteIconClick = () => {
    if (!favoriteState && !isAddLoading && !isDeleteLoading) {
      onClickAddFavorieten(payloadData());
    } else if (favoriteState && !isAddLoading && !isDeleteLoading) {
      onClickDeleteFavorieten(payloadData());
    }
  };

  const handleNavigateToClick = () => {
    if (status === 'FOR_SALE' && !isOwner) {
      navigate({
        pathname: `/product/${id}`,
        search:
          paramsProductForSaleNotOwned.merk !== '' ||
          paramsProductForSaleNotOwned.type !== '' ||
          paramsProductForSaleNotOwned.bouwjaar !== ''
            ? `?${createSearchParams(paramsProductForSaleNotOwned)}`
            : '',
      });
    } else if ((status === 'FOR_SALE' || status === 'INVENTORY') && isOwner) {
      if (isLargeScreen) {
        navigate(`/product-inventory/${id}`);
      } else {
        window.location.href = `/product-inventory/${id}`;
      }
    } else if (status === 'SOLD' && isOwner) {
      navigate(`/product-sold/${id}`);
    }
  };

  const handleClickBackground = useCallback((e) => {
    if (e.target === e.currentTarget) {
      setStockMenuState(false);
    }
  }, []);

  return (
    <ProductResultCardContainer>
      <ProductResultStockMenuBackground display={stockMenuState ? 'block' : 'none'} />
      <ProductResultStockMenuBackground display={stockMenuState ? 'block' : 'none'} onClick={handleClickBackground} />

      <ProductResultCardIconWrapper onClick={handleFavoriteIconClick}>
        {favoriteState ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </ProductResultCardIconWrapper>
      <ProductResultCardWrapper
        onClick={handleNavigateToClick}
        zIndex={stockMenuState ? '5' : 'auto'}
        cursor={!(status === 'SOLD' && !isOwner) && 'pointer'}
      >
        <ProductResultCardImageWrapper>
          <img className="productResultCardImageContent" ref={ref} src={imageFile} alt="" height={imgHeight} />
        </ProductResultCardImageWrapper>
        <ProductResultCardInfoWrapper>
          <ProductResultCardInfoLeftWrapper>
            <ProductResultCardInfoLeftPriceWrapper>
              {status === 'FOR_SALE' ? (
                <PriceLabel value={price} />
              ) : (
                <ProductResultCardStatusTextWrapper>
                  {status === 'INVENTORY' ? 'Voorraad' : status === 'SOLD' && 'Verkocht'}
                </ProductResultCardStatusTextWrapper>
              )}
            </ProductResultCardInfoLeftPriceWrapper>
            <ProductResultCardInfoLeftSeasonWrapper>
              {seasonIcon}
              <ProductResultCardInfoLeftSeasonTextWrapper>
                {condition === ConditionsType.NEW
                  ? 'Nieuw'
                  : condition === ConditionsType.USED
                  ? 'Gebruikt'
                  : condition === ConditionsType.GOOD
                  ? 'ZGAN'
                  : condition === 'Zo goed als nieuw'
                  ? 'ZGAN'
                  : condition}
              </ProductResultCardInfoLeftSeasonTextWrapper>
            </ProductResultCardInfoLeftSeasonWrapper>
          </ProductResultCardInfoLeftWrapper>
        </ProductResultCardInfoWrapper>
      </ProductResultCardWrapper>

      <ProductResultCardInfoRightWrapper zIndex={stockMenuState ? '5' : 'auto'}>
        {(status === 'INVENTORY' || status === 'FOR_SALE') && isOwner ? (
          <ProductResultCardInfoRightIconWrapper onClick={() => setStockMenuState(!stockMenuState)}>
            <MoreHorizIcon />
          </ProductResultCardInfoRightIconWrapper>
        ) : (
          ((status === 'SOLD' && isOwner) || (!isOwner && status === 'FOR_SALE')) && (
            <ProductResultCardInfoRightIconWrapper onClick={handleNavigateToClick}>
              <EastIcon />
            </ProductResultCardInfoRightIconWrapper>
          )
        )}
      </ProductResultCardInfoRightWrapper>

      <ProductResultStockMenuWrapper display={stockMenuState ? 'block' : 'none'}>
        {status === 'INVENTORY' ? (
          <>
            <ProductResultStockMenuItemWrapper
              onClick={() => navigate(`/bewerken/${id}`)}
              borderBottom="0.5px solid #E6E6E6"
              border="8px 8px 0px 0px"
            >
              Bewerken
            </ProductResultStockMenuItemWrapper>
            <ProductResultStockMenuItemWrapper
              onClick={() => setSelectedOpties('Te koop aanbieden')}
              borderBottom="0.5px solid #E6E6E6"
            >
              Advertentie publiceren
            </ProductResultStockMenuItemWrapper>
            <ProductResultStockMenuItemWrapper
              onClick={() => setSelectedOpties('Verwijderen')}
              color="#FF3D00"
              border="0px 0px 8px 8px"
            >
              Verwijderen
            </ProductResultStockMenuItemWrapper>
          </>
        ) : (
          <>
            <ProductResultStockMenuItemWrapper
              onClick={() => navigate(`/bewerken/${id}`)}
              borderBottom="0.5px solid #E6E6E6"
              border="8px 8px 0px 0px"
            >
              Bewerken
            </ProductResultStockMenuItemWrapper>
            <ProductResultStockMenuItemWrapper
              onClick={() => setSelectedOpties('Advertentie intrekken')}
              borderBottom="0.5px solid #E6E6E6"
            >
              Advertentie intrekken
            </ProductResultStockMenuItemWrapper>
            <ProductResultStockMenuItemWrapper
              onClick={() => setSelectedOpties('Verwijderen')}
              color="#FF3D00"
              border="0px 0px 8px 8px"
            >
              Verwijderen
            </ProductResultStockMenuItemWrapper>
          </>
        )}
      </ProductResultStockMenuWrapper>
      <ProductResultCardWhiteSpace display={stockMenuState ? 'block' : 'none'} />
      {selectedOpties !== '' && (
        <>
          <ProductResultPopupBackground backgroundColor="#262626" />
          <Popup
            selectedOpties={selectedOpties}
            setSelectedOpties={setSelectedOpties}
            setOpties={setOpties}
            toastId={toastId}
            setToastId={setToastId}
            toastList={toastList}
            setToastList={setToastList}
            advertentieId={advertentieId}
            overrideProductId={id}
            isListerPage={isListerPage}
            refreshUponProductResultCardAction={refreshUponProductResultCardAction}
            stockMenuState={stockMenuState}
            setStockMenuState={setStockMenuState}
          />
        </>
      )}
    </ProductResultCardContainer>
  );
};

export default ProductResultCard;
