import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../../../scss/_variables.scss';
import LicensePlateInput from '../../LicensePlateInput/LicensePlateInput';
import Button from '../../bhComponents/Button';
import FormControl from '../../bhComponents/FormControl';
import FormBasicSelect from '../../bhComponents/FormBasicSelect';
import HomeAccordion from '../HomeAccordion';
import {
  ZoekenMainWrapper,
  ZoekenTitleWrapper,
  MobileZoekenTitleWrapper,
  MobileZoekenTitleContent,
  MobileZoekenTitleIconWrapper,
  ZoekenSorterenwrapper,
  ZoekenSorterenTextWrapper,
  ZoekenFilterChipWrapper,
  ZoekenSeizonContentWrapper,
  ZoekenPrijsContentWrapper,
  ZoekenSearchButtonWrapper,
  ZoekenInchWrapper,
  ExpandablesWrapper,
} from './Zoeken.styles';
import FilterChip from 'app/components/filter-chip';
import CheckboxList, { Conditions } from 'app/components/form/checkbox-list';
import SeasonInput from 'app/components/form/season-input';
import PriceSlider from 'app/components/form/price-slider';
import useBrands from 'app/hooks/useBrands';
import useModels from 'app/hooks/useModels';

const sorterenOptions = [
  {
    label: 'Toegevoegd: nieuw naar oud',
    value: 'Toegevoegd: nieuw naar oud',
  },
  {
    label: 'Toegevoegd: oud naar nieuw',
    value: 'Toegevoegd: oud naar nieuw',
  },
  {
    label: 'Prijs: hoog naar laag',
    value: 'Prijs: hoog naar laag',
  },
  {
    label: 'Prijs: laag naar hoog',
    value: 'Prijs: laag naar hoog',
  },
];

const Zoeken = ({
  isSearchMenuOpen,
  isMobileScreen,
  kentekenInputValue,
  setKentekenInputValue,
  setAutogegevens,
  carModelAndYear,
  filterSearchValues,
  setFilterSearchValues,
  setSearchMenuState,
  merkValue,
  setMerkValue,
  modelValue,
  setModelValue,
  bouwjaarValue,
  setBouwjaarValue,
  sorterenValue,
  setSorterenValue,
  seasonValue,
  setSeasonValue,
  priceRangeValues,
  setPriceRangeValues,
  handleSearchButtonClick,
  filterMerks,
  filterInches,
  filterConditie,
  toastId,
  setToastId,
  toastList,
  setToastList,
  min,
  max,
}) => {
  const [localBrandValue, setLocalBrandValue] = useState();
  const [localModelValue, setLocalModelValue] = useState();
  const [localYearValue, setLocalYearValue] = useState();
  const ref = useRef(null);

  const temporaryFilterConditieArray = [];
  const temporaryFilterInchArray = [];
  const temporaryFilterMerkArray = [];
  const { data: brandsData } = useBrands();
  const { data: modelsData } = useModels(merkValue);

  const brandOptions = useMemo(() => {
    if (brandsData?.brands) {
      return brandsData?.brands.map((brand) => ({ label: brand, value: brand }));
    } else {
      return [];
    }
  }, [brandsData?.brands]);

  const modelOptions = useMemo(() => {
    if (modelsData?.models) {
      return modelsData?.models.map((modelInfo) => ({ label: modelInfo.model, value: modelInfo.model }));
    } else {
      return [];
    }
  }, [modelsData?.models]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear() + 1;
    const tempModelData = modelsData?.models.filter((item) => item.model === localModelValue.value)[0];
    const tempYearOptions = [];

    if (tempModelData?.bouwjaar_van) {
      for (let i = tempModelData.bouwjaar_van; i <= (tempModelData.bouwjaar_tot || currentYear); i++) {
        tempYearOptions.push({
          label: i.toString(),
          value: i.toString(),
        });
      }
      return tempYearOptions;
    }
    return [];
  }, [localModelValue]);

  const handleBrandChange = useCallback((_, option) => {
    setLocalBrandValue(option);
    setMerkValue(option?.value);
  }, []);

  const handleModelChange = useCallback((_, option) => {
    setLocalModelValue(option);
    setModelValue(option?.value);
  }, []);

  const handleYearChange = useCallback((_, option) => {
    setLocalYearValue(option);
    setBouwjaarValue(option?.value);
  }, []);

  useEffect(() => {
    if (merkValue === '' && modelValue === '' && bouwjaarValue === '') {
      setLocalBrandValue();
      setLocalModelValue();
      setLocalYearValue();
    }
  }, [merkValue, modelValue, bouwjaarValue]);

  useEffect(() => {
    if (localBrandValue?.label) {
      setModelValue('');
      setLocalModelValue();
      setBouwjaarValue('');
      setLocalYearValue();
    }
  }, [localBrandValue]);

  useEffect(() => {
    if (localModelValue?.label && localYearValue?.label) {
      const modelAvailableInThatYear = yearOptions?.filter((yearObj) => yearObj?.label === localYearValue?.label);
      if (!modelAvailableInThatYear?.length) {
        setBouwjaarValue();
        setLocalYearValue();
      }
    }
  }, [localModelValue]);

  if (filterConditie) {
    Object.keys(filterConditie).forEach((key) => {
      const label =
        key.toLowerCase() === 'gebruikt'
          ? Conditions.USED
          : key.toLowerCase() === 'nieuw'
          ? Conditions.NEW
          : Conditions.GOOD;
      temporaryFilterConditieArray.push({
        name: key,
        label: filterConditie[key],
        checked: async () => await filterSearchValues.includes(label),
      });
    });
  }

  if (filterMerks) {
    Object.keys(filterMerks).forEach((key) => {
      temporaryFilterMerkArray.push({
        name: key,
        label: filterMerks[key],
        checked: async () => await filterSearchValues.includes(key),
      });
    });
  }
  if (filterInches) {
    Object.keys(filterInches).forEach((key) => {
      temporaryFilterInchArray.push({
        name: key,
        label: filterInches[key],
        checked: async () => await filterSearchValues.includes(key),
      });
    });
  }

  const conditieContent = (
    <div>
      {filterConditie && (
        <CheckboxList
          filterSearchValues={filterSearchValues}
          setFilterSearchValues={setFilterSearchValues}
          items={temporaryFilterConditieArray}
          readMoreMax={3}
        />
      )}
    </div>
  );

  const seizoenContent = (
    <ZoekenSeizonContentWrapper>
      <SeasonInput
        seasonValue={seasonValue}
        setSeasonValue={setSeasonValue}
        filterSearchValues={filterSearchValues}
        setFilterSearchValues={setFilterSearchValues}
      />
    </ZoekenSeizonContentWrapper>
  );

  const merkContent = (
    <div>
      {filterMerks && (
        <CheckboxList
          filterSearchValues={filterSearchValues}
          setFilterSearchValues={setFilterSearchValues}
          items={temporaryFilterMerkArray}
          readMoreMax={3}
        />
      )}
    </div>
  );

  const prijsContent = (
    <ZoekenPrijsContentWrapper>
      {typeof min.current === 'number' && typeof max.current === 'number' && (
        <PriceSlider
          priceRangeValues={priceRangeValues}
          setPriceRangeValues={setPriceRangeValues}
          min={min}
          max={max}
          filterSearchValues={filterSearchValues}
          setFilterSearchValues={setFilterSearchValues}
        />
      )}
    </ZoekenPrijsContentWrapper>
  );
  const handleChange = useCallback((_, options) => {
    setSorterenValue(options);
  }, []);

  const inchContent = (
    <ZoekenInchWrapper>
      {filterInches && (
        <CheckboxList
          filterSearchValues={filterSearchValues}
          setFilterSearchValues={setFilterSearchValues}
          items={temporaryFilterInchArray}
          readMoreMax={3}
        />
      )}
    </ZoekenInchWrapper>
  );

  return (
    <ZoekenMainWrapper
      ref={ref}
      width={isMobileScreen && isSearchMenuOpen ? '100%' : '22.5%'}
      height={isMobileScreen && isSearchMenuOpen ? 'auto' : 'fit-content'}
      display={isMobileScreen && isSearchMenuOpen ? 'flex' : 'none'}
      position={isMobileScreen && isSearchMenuOpen ? 'fixed' : 'relative'}
      background={styles.white}
    >
      {isMobileScreen ? (
        <MobileZoekenTitleWrapper>
          <MobileZoekenTitleContent color={styles.primaryColor}>Zoeken</MobileZoekenTitleContent>
          <MobileZoekenTitleIconWrapper onClick={() => setSearchMenuState(false)}>
            <CloseIcon />
          </MobileZoekenTitleIconWrapper>
        </MobileZoekenTitleWrapper>
      ) : (
        <ZoekenTitleWrapper>Zoeken</ZoekenTitleWrapper>
      )}
      <LicensePlateInput
        handleSearchButtonClick={handleSearchButtonClick}
        kentekenInputValue={kentekenInputValue}
        setKentekenInputValue={setKentekenInputValue}
        setAutogegevens={setAutogegevens}
        toastId={toastId}
        toastList={toastList}
        setToastList={setToastList}
        setToastId={setToastId}
        brandOptions={brandOptions}
        localBrandValue={localBrandValue}
        handleBrandChange={handleBrandChange}
        modelOptions={modelOptions}
        localModelValue={localModelValue}
        handleModelChange={handleModelChange}
        yearOptions={yearOptions}
        localYearValue={localYearValue}
        handleYearChange={handleYearChange}
        carModelAndYear={carModelAndYear}
      />

      <ZoekenSorterenwrapper>
        <ZoekenSorterenTextWrapper>Sorteren</ZoekenSorterenTextWrapper>
        <FormControl inputSize="small" id="sort">
          <FormBasicSelect options={sorterenOptions} onChange={handleChange} value={sorterenValue} />
        </FormControl>
      </ZoekenSorterenwrapper>

      <ZoekenFilterChipWrapper>
        {filterSearchValues.map((item) => (
          <FilterChip
            key={item}
            label={item}
            filterSearchValues={filterSearchValues}
            setFilterSearchValues={setFilterSearchValues}
            seasonValue={seasonValue}
            setSeasonValue={setSeasonValue}
            color={styles.primaryColor}
            backgroundColor={styles.primaryColorContact}
            min={min}
            max={max}
            priceRangeValues={priceRangeValues}
            setPriceRangeValues={setPriceRangeValues}
          />
        ))}
        {filterSearchValues.length > 0 && (
          <FilterChip
            label="Alle filters wissen"
            filterSearchValues={filterSearchValues}
            setFilterSearchValues={setFilterSearchValues}
            seasonValue={seasonValue}
            setSeasonValue={setSeasonValue}
            color="#666666"
            backgroundColor="#E6E6E6"
            min={min}
            max={max}
            priceRangeValues={priceRangeValues}
            setPriceRangeValues={setPriceRangeValues}
          />
        )}
      </ZoekenFilterChipWrapper>

      <ExpandablesWrapper>
        <HomeAccordion title="Conditie" content={conditieContent} />
        <HomeAccordion title="Seizoen" content={seizoenContent} />
        <HomeAccordion title="Merk" content={merkContent} />
        <HomeAccordion title="Prijs" content={prijsContent} overflow />
        <HomeAccordion title="Inch" content={inchContent} />
      </ExpandablesWrapper>

      <ZoekenSearchButtonWrapper>
        <Button fullwidth onClick={handleSearchButtonClick}>
          Zoeken
        </Button>
      </ZoekenSearchButtonWrapper>
    </ZoekenMainWrapper>
  );
};

export default Zoeken;
